import React from 'react';
import { getIn, useFormikContext } from 'formik';
import { keyframes } from 'tss-react';
import { makeStyles } from 'tss-react/mui';

import { useTranslation } from '../../../../i18n';
import FormHelperText from '../../FormHelperText';

const appearFromTopAnimation = keyframes`
  0: {
    opacity: 0;
    transform: translateY(20px) scaleY(0);
    maxHeight: 0;
  },
  100: {
    opacity: 1;
    transform: translateY(0) scaleY(1);
    maxHeight: 70;
  },
`;

const useStyles = makeStyles()((theme: any) => ({
  errorList: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    marginRight: 'auto',
    '& > p': {
      marginLeft: theme.spacing(5),
      opacity: 0,
      maxHeight: 0,
      transform: 'translateY(20px) scaleY(0)',
      animation: appearFromTopAnimation,
      animationDuration: theme.animation.duration.shortIn,
      animationTimingFunction: theme.animation.timingFunction.moveIn,
      animationFillMode: 'forwards',
    },
  },
}));

type Props = {
  gridkey: string;
  fields: Array<string>;
  requestError?: string | null;
};

const ErrorList = (props: Props) => {
  const { gridkey, fields, requestError } = props;
  const { errors, touched } = useFormikContext();
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    // @ts-ignore
    <div gridkey={gridkey} className={classes.errorList}>
      {fields.map(fieldName => {
        const error = getIn(errors, fieldName);
        const show = getIn(touched, fieldName) && !!error;
        if (show)
          return <FormHelperText error>{t(`schemas:${error}`)}</FormHelperText>;
        return null;
      })}
      {requestError && (
        <FormHelperText error>{t(`common:${requestError}`)}</FormHelperText>
      )}
    </div>
  );
};

export default ErrorList;

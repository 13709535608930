import React from 'react';
import { makeStyles } from 'tss-react/mui';

export enum DigitInputStatus {
  EMPTY = 'EMPTY',
  TYPING = 'TYPING',
  ERROR = 'ERROR',
}

const useStyles = makeStyles<{ status: DigitInputStatus }>()(
  (theme: any, { status }) => {
    let backgroundColor = theme.palette.background.defaultGrey;
    if (status === DigitInputStatus.TYPING) {
      backgroundColor = theme.palette.main.boringLight;
    } else if (status === DigitInputStatus.ERROR) {
      backgroundColor = theme.palette.destructive.xlight;
    }

    const backgroundColorUnderline = theme.palette.background.defaultGrey;
    if (status === DigitInputStatus.TYPING) {
      backgroundColor = theme.palette.main.main;
    } else if (status === DigitInputStatus.ERROR) {
      backgroundColor = theme.palette.destructive.main;
    }

    return {
      container: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
      },
      digitInput: {
        width: '57px',
        padding: theme.spacing(2),
        borderRadius: theme.shape.borderRadiusLarge,
        fontSize: '40px',
        textAlign: 'center',
        backgroundColor,
      },
      underline: {
        height: theme.spacing(1),
        borderRadius: theme.shape.borderRadiusLarge,
        backgroundColor: backgroundColorUnderline,
      },
    };
  },
);

const DigitInput = React.forwardRef<
  HTMLInputElement,
  { status?: DigitInputStatus } & React.HTMLProps<HTMLInputElement>
>(({ status = DigitInputStatus.EMPTY, ...props }, ref) => {
  const { classes } = useStyles({ status });
  return (
    <div className={classes.container}>
      <input
        ref={ref}
        className={classes.digitInput}
        required
        maxLength={1}
        size={1}
        pattern="\d*"
        autoComplete="off"
        inputMode="numeric"
        {...props}
      />
      <div className={classes.underline} />
    </div>
  );
});

DigitInput.displayName = 'DigitInput';

export default DigitInput;

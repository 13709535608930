import { object, string } from 'yup';

const nameRegExp = /^[- A-Za-zÀ-ÖØ-öø-ÿ']+$/;

const TlsNameSchema = object().shape(
  {
    tlsFirstName: string()
      .required('userschema_firstName')
      .max(50, 'userschema_max_50characters')
      .matches(nameRegExp, 'userschema_no_special_characters'),
    tlsLastName: string()
      .max(50, 'userschema_max_50characters')
      .matches(nameRegExp, 'userschema_no_special_characters')
      .when('tlsFirstName', {
        is: val => val && val.length > 0,
        then: string().required('userschema_lastName'),
        otherwise: string(),
      }),
  },
  [['tlsFirstName', 'tlsLastName']],
);

export default TlsNameSchema;

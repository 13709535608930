import React from 'react';
import { Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Field, FieldProps, Form } from 'formik';
import isEmpty from 'lodash/isEmpty';

import { useTranslation } from '../../../../../../i18n';
import { useIsAccountAlreadyUnlocked } from '../../../../../../src/hooks/query';

import ErrorList from '../../../../../atoms/Fields/Error/ErrorList';
import TextFieldController from '../../../../../atoms/Fields/TextField/TextFieldController';
import Typography from '../../../../../atoms/Typography';
import { TlsNameFormFields } from '../../containers/TlsNameFormContainer/TlsNameFormContainer';

const useStyles = makeStyles()((theme: any) => ({
  tlsNameform: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'repeat(5, 34px)',
    gap: theme.spacing(5),
    marginTop: theme.spacing(5),
  },
}));

type Props = {} & Partial<FieldProps<TlsNameFormFields>>;

const TlsNameFormView = (props: Props) => {
  const { form } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();
  const fieldsName = ['tlsFirstName', 'tlsLastName'];
  const isAccountAlreadyUnlocked = useIsAccountAlreadyUnlocked();

  return (
    <Form className={classes.tlsNameform}>
      {fieldsName.map(name => (
        <Field
          type="text"
          key={name}
          name={name}
          component={TextFieldController}
          placeholder={t(`tls_login_page__name_form_${name}`)}
          hideError
        />
      ))}
      {isAccountAlreadyUnlocked && (
        <Typography variant="bodySmall" color="green.500">
          {t('login_page__account_already_unlocked')}
        </Typography>
      )}
      <ErrorList gridkey="error" fields={fieldsName} />
      <Button
        variant="contained"
        color="primary"
        type="submit"
        disabled={!form?.isValid || isEmpty(form?.touched)}
      >
        {t('validate')}
      </Button>
      <Button variant="text-hover" type="reset">
        {t('tls_login_page__name_form__skip_button')}
      </Button>
    </Form>
  );
};

export default TlsNameFormView;

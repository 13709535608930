import { array, boolean, object, string } from 'yup';

const LoginOneTimePINSchema = () =>
  object().shape({
    oneTimePIN: array()
      .of(
        string()
          .matches(/^\d*$/)
          .length(1)
          .required(),
      )
      .min(6)
      .max(6)
      .required(),
    shouldRememberDevice: boolean()
      .oneOf([true, false])
      .required(),
  });

export default LoginOneTimePINSchema;

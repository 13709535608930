import React from 'react';
import { cn } from '@kiiwi/ui';

type InputGroupProps = {
  children: React.ReactNode;
  className?: string;
  defaultValue?: string[];
  value?: string[];
  onInput?: React.CompositionEventHandler<HTMLInputElement>;
  name?: string;
  numeric?: boolean;
  onValid?: () => void;
};

export const InputGroup = React.forwardRef<HTMLDivElement, InputGroupProps>(
  (
    {
      children,
      className,
      defaultValue,
      value: externalValue,
      onInput: externalOnInput,
      name = 'input-group.',
      numeric = false,
      onValid,
    },
    ref,
  ) => {
    const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
      HTMLInputElement.prototype,
      'value',
    )?.set;
    const fieldCount = React.Children.count(children);
    const fieldsRef = React.useRef(new Map<number, HTMLInputElement>());
    const [internalValue, setInternalValue] = React.useState(
      defaultValue || Array.from({ length: fieldCount }, () => ''),
    );
    const value = externalValue || internalValue;

    const internalOnInput: React.CompositionEventHandler<HTMLInputElement> = event => {
      const field = event.currentTarget;
      const index = parseInt(field.name.replace(name, ''), 10);
      const fieldValue = field.value;
      setInternalValue(prevValue =>
        prevValue.map((v, i) => (i === index ? fieldValue : v)),
      );
    };

    const onInput: React.CompositionEventHandler<HTMLInputElement> = event => {
      const field = event.currentTarget;
      const index = parseInt(field.name.replace(name, ''), 10);
      if (!numeric || /^\d*$/.test(field.value)) {
        externalOnInput?.(event);
        if (!externalValue) {
          internalOnInput(event);
        }
        if (field.value.length === field.maxLength) {
          const nextIndex = index + 1;
          if (nextIndex < fieldCount) {
            const nextField = fieldsRef.current.get(nextIndex);
            if (nextField) {
              nextField.focus();
              nextField.setSelectionRange(0, 0);
            }
          }
        }
      }
    };

    const onBeforeInput: React.CompositionEventHandler<HTMLInputElement> = event => {
      const field = event.currentTarget;
      if (field.value.length === field.maxLength && nativeInputValueSetter) {
        event.preventDefault();
        nativeInputValueSetter.call(field, event.data);
        const inputEvent = new InputEvent('input', { bubbles: true });
        field.dispatchEvent(inputEvent);
      }
    };

    const onKeyDown: React.KeyboardEventHandler<HTMLInputElement> = event => {
      const field = event.currentTarget;
      const index = parseInt(field.name.replace(name, ''), 10);
      if (event.key === 'Backspace') {
        const prevIndex = index - 1;
        if (
          prevIndex > -1 &&
          field.selectionStart === 0 &&
          field.selectionEnd === 0
        ) {
          const prevField = fieldsRef.current.get(prevIndex);
          if (prevField && nativeInputValueSetter) {
            event.preventDefault();
            prevField.focus();
            prevField.setSelectionRange(
              prevField.maxLength,
              prevField.maxLength,
            );
            nativeInputValueSetter.call(
              prevField,
              prevField.value.slice(0, -1),
            );
            const inputEvent = new InputEvent('input', { bubbles: true });
            prevField.dispatchEvent(inputEvent);
          }
        }
      } else if (event.key === 'ArrowLeft') {
        const prevIndex = index - 1;
        if (
          prevIndex > -1 &&
          field.selectionStart === 0 &&
          field.selectionEnd === 0
        ) {
          const prevField = fieldsRef.current.get(prevIndex);
          if (prevField) {
            event.preventDefault();
            prevField.focus();
            prevField.setSelectionRange(
              prevField.maxLength - 1,
              prevField.maxLength - 1,
            );
          }
        }
      } else if (event.key === 'ArrowRight') {
        const nextIndex = index + 1;
        if (
          nextIndex < fieldCount &&
          field.selectionStart === field.value.length &&
          field.selectionEnd === field.value.length
        ) {
          const nextField = fieldsRef.current.get(nextIndex);
          if (nextField) {
            event.preventDefault();
            nextField.focus();
            nextField.setSelectionRange(1, 1);
          }
        }
      }
    };

    const onPaste: React.ClipboardEventHandler<HTMLInputElement> = event => {
      let clipboardData = event.clipboardData.getData('text');
      if (numeric) {
        clipboardData = clipboardData.replace(/\D/g, '');
      }
      if (nativeInputValueSetter) {
        event.preventDefault();
        let startIndex = 0;
        for (let index = 0; index < fieldCount; ++index) {
          const field = fieldsRef.current.get(index);
          if (!field) {
            break;
          }
          const endIndex = startIndex + field.maxLength;
          const fieldValue = clipboardData.slice(startIndex, endIndex);
          nativeInputValueSetter.call(field, fieldValue);
          const inputEvent = new InputEvent('input', { bubbles: true });
          field.dispatchEvent(inputEvent);
          startIndex = endIndex;
        }
      }
    };

    const onChange: React.ChangeEventHandler<HTMLInputElement> = () => {
      if (onValid) {
        for (const field of fieldsRef.current.values()) {
          if (!field.checkValidity()) {
            return;
          }
        }
        onValid();
      }
    };

    return (
      <div ref={ref} className={cn('flex justify-between', className)}>
        {React.Children.map(children, (element, index) =>
          React.isValidElement<
            {
              ref: React.Ref<HTMLInputElement>;
            } & React.HTMLProps<HTMLInputElement>
          >(element)
            ? React.cloneElement(element, {
                ref: (field: HTMLInputElement | null) => {
                  if (field) {
                    fieldsRef.current.set(index, field);
                  } else {
                    fieldsRef.current.delete(index);
                  }
                },
                name: name + index,
                value: value[index],
                onInput,
                onBeforeInput,
                onKeyDown,
                onPaste,
                onChange,
              })
            : element,
        )}
      </div>
    );
  },
);

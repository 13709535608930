import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Checkbox,
  checkboxClasses,
  FormControlLabel,
} from '@mui/material';
import { Form, FormikProps } from 'formik';
import { makeStyles } from 'tss-react/mui';

import { asyncActions } from '@docavenue/core';

import {
  GAActionStatus,
  GAAuthenticationActions,
  GAAuthenticationLabels,
  GACategories,
} from '../../../../../../src/constants/tracking';
import { useAnalyticsTracker } from '../../../../../../src/hooks/utils';
import { ResendOneTimePIN } from './ResendOneTimePIN';

import DigitInput from '@/components/atoms/DigitInput';
import { DigitInputStatus } from '@/components/atoms/DigitInput/DigitInput';
import FontAwesomeIcon from '@/components/atoms/FontawesomeIcon/FontawesomeIcon';
import Tooltip from '@/components/atoms/Tooltip';
import Typography from '@/components/atoms/Typography';
import LoginOneTimePINSchema from '@/components/FormikSchema/LoginOneTimePINSchema';
import FormikBuilder from '@/components/organisms/FormikBuilder';
import { Trans, useTranslation } from '@/i18n';
import { authenticationActions } from '@/src/actions';
import { InputGroup } from '@/src/components/InputGroup';
import { useLoginAction } from '@/src/hooks/actions';

const useStyles = makeStyles()((theme: any) => ({
  bold: { fontWeight: 'bold' },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(5),
  },
  error: {
    color: theme.palette.destructive.main,
    '& a': {
      fontWeight: 'bold',
      textDecoration: 'underline',
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  footer: {
    textAlign: 'center',
    marginTop: 'auto',
  },
  rememberDevice: {
    display: 'flex',
  },
  tooltip: {
    display: 'flex',
    alignItems: 'center',
  },
}));

type LoginFormOneTimePINInitialValue = {
  oneTimePIN: string[];
  shouldRememberDevice: boolean;
};
type Props = {
  autoLogin: {
    tokenHash?: string;
    hash?: string;
    data?: string;
    tsToken?: string;
  };
  mfaToken: string;
  goToLogin: () => void;
};

const LoginFormOneTimePINStepView = ({
  autoLogin,
  mfaToken,
  goToLogin,
}: Props) => {
  const { classes } = useStyles() as any;
  const { t } = useTranslation(['common', 'schemas']);
  const triggerAnalyticsEvent = useAnalyticsTracker(
    GACategories.Authentication,
  );
  const dispatch = useDispatch();
  const loginAction = useLoginAction();
  const authenticationError = useSelector(state => state.authentication.error);
  const { status, code } = authenticationError || {};
  const maskedUsername = useSelector(
    state => state.authentication.item?.maskedUsername,
  );
  const oneTimePINCreationDate = useSelector(
    state => state.authentication.item?.oneTimePINCreationDate,
  );
  const formRef = useRef<FormikProps<LoginFormOneTimePINInitialValue>>(null);
  const supportPhoneNumber = (t('support_phone_number') as string).replace(
    /\s/g,
    '',
  );
  const name = 'oneTimePIN.';
  const fieldCount = 6;

  const handleResendOneTimePINClick = () => {
    if (mfaToken) {
      dispatch(authenticationActions.resendOneTimePIN(mfaToken));
      for (let index = 0; index < fieldCount; ++index) {
        formRef.current?.setFieldValue(`${name}${index}`, '');
      }
    }
  };

  const handleInput: React.CompositionEventHandler<HTMLInputElement> = event => {
    const field = event.currentTarget;
    const index = parseInt(field.name.replace(name, ''), 10);
    formRef.current?.setFieldValue(`${name}${index}`, field.value);
  };

  const handleSubmit = async (
    values: LoginFormOneTimePINInitialValue,
  ): Promise<void> => {
    try {
      await asyncActions(
        dispatch,
        loginAction({
          ...autoLogin,
          mfaToken,
          oneTimePIN: values.oneTimePIN.join(''),
          shouldRememberDevice: values.shouldRememberDevice,
        }),
      );
    } catch (e) {
      // empty
    }
  };

  const formikProps = {
    formRef,
    initialValues: {
      oneTimePIN: ['', '', '', '', '', ''],
      shouldRememberDevice: false,
    },
    onSubmit: handleSubmit,
    validationSchema: LoginOneTimePINSchema,
    validateOnBlur: false,
    validateOnChange: false,
  };

  useEffect(() => {
    if (mfaToken) {
      dispatch(authenticationActions.getOneTimePINDetails(mfaToken));
    }
  }, [dispatch, mfaToken]);

  useEffect(() => {
    if (
      (status === 401 && code === 'MFA_EXPIRED') ||
      (status === 404 && code === 'MFA_NOT_FOUND')
    ) {
      dispatch(authenticationActions.resetError());
      goToLogin();
    }
    if (status && status !== 200 && code) {
      triggerAnalyticsEvent(
        GAAuthenticationActions.FailMultiFactorAuthentication,
        GAActionStatus.ERROR,
        GAAuthenticationLabels[code],
      );
    }
  }, [code, dispatch, status]);

  return (
    <FormikBuilder {...formikProps}>
      {({
        isSubmitting,
        setFieldValue,
        values,
      }: FormikProps<LoginFormOneTimePINInitialValue>) => (
        <>
          <div>
            <Typography component="h2">
              {t('login_page__new_login_second_factor_title')}
            </Typography>
            <Typography component="span">
              {t('login_page__new_login_second_factor_body_email')}
            </Typography>
            {!!maskedUsername && (
              <Typography
                className={classes.bold}
              >{`${maskedUsername}.`}</Typography>
            )}
            <div>
              <Typography component="span">
                {t('login_page__new_login_second_factor_body_duration')}
              </Typography>
              <Typography className={classes.bold} component="span">
                {` ${t(
                  'login_page__new_login_second_factor_body_duration_value',
                )}`}
              </Typography>
            </div>
          </div>
          <Form className={classes.form}>
            <InputGroup
              value={values.oneTimePIN?.map(element => element ?? '')}
              onInput={handleInput}
              name={name}
              numeric
            >
              {Array.from({ length: fieldCount }, (_, index) => {
                let fieldStatus = DigitInputStatus.EMPTY;
                if (values.oneTimePIN[index]) {
                  fieldStatus = DigitInputStatus.TYPING;
                }
                if (status === 401 && code === 'AUTH_ERROR_018') {
                  fieldStatus = DigitInputStatus.ERROR;
                }
                return (
                  <DigitInput
                    key={index}
                    status={fieldStatus}
                    autoFocus={index === 0}
                    disabled={isSubmitting}
                    onFocus={() => dispatch(authenticationActions.resetError())}
                  />
                );
              })}
            </InputGroup>
            {status === 406 && code === 'MFA_DIFFERENT_DEVICE' && (
              <Typography className={classes.error}>
                {t('mfa_from_different_device')}
              </Typography>
            )}
            {status === 429 && code === 'MFA_TOO_MANY_REQUESTS' && (
              <Typography className={classes.error}>
                {t('too_many_one_time_pin_creations')}
              </Typography>
            )}
            {status === 429 && code === 'MFA_TOO_MANY_ATTEMPTS' && (
              <Typography className={classes.error}>
                {t('too_many_authentication_attempts')}
              </Typography>
            )}
            {status === 401 && code === 'AUTH_ERROR_018' && (
              <Typography className={classes.error}>
                <Trans
                  i18nKey="invalid_pin"
                  components={[
                    <a
                      key="link"
                      href={t('zendesk_login')}
                      target="_blank"
                      rel="noreferrer"
                    />,
                  ]}
                />
              </Typography>
            )}
            <div className={classes.rememberDevice}>
              <FormControlLabel
                sx={{ marginRight: theme => theme.spacing(2) }}
                control={
                  <Checkbox
                    name="shouldRememberDevice"
                    checked={values.shouldRememberDevice}
                    sx={{
                      [`&.${checkboxClasses.checked}`]: {
                        color: theme => theme.palette.main.main,
                      },
                    }}
                    onChange={() => {
                      setFieldValue(
                        'shouldRememberDevice',
                        !values.shouldRememberDevice,
                      );
                    }}
                  />
                }
                label={
                  <Typography variant="body">{t('remember_device')}</Typography>
                }
              />
              <Tooltip title={t('remember_device_tooltip')}>
                <div className={classes.tooltip}>
                  <FontAwesomeIcon
                    name="circle-info:regular"
                    className="h-5 w-5"
                  />
                </div>
              </Tooltip>
            </div>
            <div className={classes.buttonContainer}>
              <Button
                variant="text"
                disabled={isSubmitting}
                onClick={goToLogin}
                startIcon={
                  <FontAwesomeIcon
                    name="angle-left:regular"
                    className="h-3 w-3"
                  />
                }
              >
                <span>{t('back_action_label')}</span>
              </Button>
              <Button
                className={classes.loginButton}
                variant="contained"
                color="primary"
                type="submit"
                disabled={
                  !values.oneTimePIN.every(element => element) || isSubmitting
                }
              >
                {t('auth_card__button_login')}
              </Button>
            </div>
          </Form>
          {oneTimePINCreationDate && (
            <ResendOneTimePIN
              oneTimePINCreationDate={oneTimePINCreationDate}
              hasReachedLimit={
                status === 429 && code === 'MFA_TOO_MANY_REQUESTS'
              }
              onClick={handleResendOneTimePINClick}
            />
          )}
          <Typography className={classes.footer}>
            {t('login_page__new_login_second_factor_footer_title')}{' '}
            <a
              className="text-action-secondary-enable cursor-pointer font-bold"
              href={`tel:${supportPhoneNumber}`}
            >
              {`${t('contact_support')} ${t('support_phone_number')}`}
            </a>
          </Typography>
        </>
      )}
    </FormikBuilder>
  );
};

export default LoginFormOneTimePINStepView;

import React from 'react';
import { makeStyles } from 'tss-react/mui';

import { useTranslation } from '../../../../../../i18n';

import Typography from '../../../../../atoms/Typography';
import ConfirmCodeFormContainer from '../../containers/ConfirmCodeFormContainer';
import TlsNameFormContainer from '../../containers/TlsNameFormContainer';
import { TlsNameFormFields } from '../../containers/TlsNameFormContainer/TlsNameFormContainer';

const useStyles = makeStyles()((theme: any) => ({
  tsLoginSubtitle: {
    fontSize: theme.size.font.md,
    fontWeight: 500,
    marginTop: theme.spacing(6),
  },
  problem: {
    marginTop: 'auto',
  },
}));

type Props = {
  isTlsNameForm: boolean;
  onSubmitTlsName: (name: TlsNameFormFields | null) => void;
  onSubmitTlsCode: (securityCode: string) => void;
};

const TlsLoginFormView = (props: Props) => {
  const { isTlsNameForm, onSubmitTlsName, onSubmitTlsCode } = props;
  const { classes } = useStyles();
  const { t } = useTranslation('common');

  return (
    <>
      <div>
        <Typography component="h2">
          {t('login_page_tls__catchphrase')}
        </Typography>
        <Typography className={classes.tsLoginSubtitle}>
          {isTlsNameForm
            ? t('tls_login_page__name_form_subtitle')
            : t('tls_login_page__security_code_subtitle')}
        </Typography>
      </div>
      {isTlsNameForm ? (
        <TlsNameFormContainer onSubmit={onSubmitTlsName} />
      ) : (
        <ConfirmCodeFormContainer onSubmit={onSubmitTlsCode} />
      )}
      <div className={classes.problem}>
        <Typography component="span">
          {t('login_page_problemsecuritycode')}
        </Typography>
        <a href={t('zendesk_login')} target="_blank" rel="noreferrer">
          {t('login_page_contactus')}
        </a>
        <div className="heart" />
      </div>
    </>
  );
};

export default TlsLoginFormView;

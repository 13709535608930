import React, { useState } from 'react';
import { useRouter } from 'next/router';

import LoginFormOneTimePINStepView from '../../views/LoginFormOneTimePINStepView';
import LoginFormPasswordStepView from '../../views/LoginFormPasswordStepView';
import LoginFormUsernameStepView from '../../views/LoginFormUsernameStepView';

import Routes from '@/src/constants/routes';
import { unpack } from '@/src/utils';

type Props = {
  autoLogin: {
    tokenHash?: string;
    hash?: string;
    data?: string;
    tsToken?: string;
  };
};

const LoginFormContainer = ({ autoLogin }: Props) => {
  const router = useRouter();
  const [username, setUsername] = useState('');
  const mfaToken = unpack(router.query.mfaToken);
  const { mfaToken: _, ...rest } = router.query;

  const goToLogin = () => {
    router.push({ pathname: Routes.LOGIN, query: rest });
    setUsername('');
  };

  const goToPassword = (newUsername: string): void => {
    router.push({ pathname: Routes.LOGIN, query: rest });
    setUsername(newUsername);
  };

  const goToOneTimePIN = (newMfaToken: string) => {
    router.push({
      pathname: Routes.LOGIN,
      query: { mfaToken: newMfaToken },
    });
    setUsername('');
  };

  const goToSignUp = (): void => {
    router.push({ pathname: Routes.SIGNUP });
  };

  const goToForgotPassword = (): void => {
    router.push({ pathname: Routes.FORGOT_PASSWORD });
  };

  if (mfaToken) {
    return (
      <LoginFormOneTimePINStepView
        autoLogin={autoLogin}
        mfaToken={mfaToken}
        goToLogin={goToLogin}
      />
    );
  }
  if (username) {
    return (
      <LoginFormPasswordStepView
        autoLogin={autoLogin}
        username={username}
        goToLogin={goToLogin}
        goToOneTimePIN={goToOneTimePIN}
        goToForgotPassword={goToForgotPassword}
      />
    );
  }
  return (
    <LoginFormUsernameStepView
      goToPassword={goToPassword}
      goToSignUp={goToSignUp}
    />
  );
};

export default LoginFormContainer;
